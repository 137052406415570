import React, { useMemo } from 'react'

import SwiperSlides from '../swiper/SwiperSlides'
import DailyCheckinContentBanner from './DailyCheckinContentBanner'

import { useAds } from '@/hooks/ads/useAds'

import { ADS_DEFAULT } from '@/constants/ads'

function DailyCheckinBanner() {
  const { data } = useAds()

  const listBanners = useMemo(() => {
    if (!data || !data.length) {
      return [ADS_DEFAULT].map((item) => {
        return <DailyCheckinContentBanner adsDetails={item} key={item._id} />
      })
    }

    return data.map((item) => {
      return <DailyCheckinContentBanner adsDetails={item} key={item._id} />
    })
  }, [data])

  return (
    <div className="daily-checkin__banner-section">
      <SwiperSlides
        items={listBanners}
        slidesPerView={1}
        pagination={true}
        dynamicMainBullets={5}
        identifierPrefixCls="swiper-daily-checkin-banner"
        showNavigationButtons={false}
        autoplay={true}
        delayAutoplay={3000}
      />
    </div>
  )
}

export default DailyCheckinBanner
