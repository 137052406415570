import { WalletReadyState } from '@solana/wallet-adapter-base'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
import { encode } from 'bs58'

import AdapterInterface, { Chain } from './adapter.interface'

// TODO: Change it to solana icon
import icon from './assets/binance.png'

class PhantomSolanaAdapter implements AdapterInterface {
  // Private provider
  private provider = new PhantomWalletAdapter()
  // Public params
  public name = this.provider.name
  public chain = Chain.solana
  public icon = icon
  public url = this.provider.url
  public color =
    'linear-gradient(90deg, rgb(144, 88, 216) 0%, rgb(83, 75, 177) 100%)'
  public isInstalled = this.provider.readyState === WalletReadyState.Installed
  // Public functions
  public connect = async () => {
    await this.provider.connect()
  }
  public install = () => window.open(this.url, '_blank')
  public signMessage = async (msg: string) => {
    const buf = new TextEncoder().encode(msg)
    const sig = await this.provider.signMessage(buf)
    return encode(sig)
  }
  public getAddress = async () => {
    await this.connect()
    const address = this.provider.publicKey?.toString()
    if (!address) throw new Error('Invalid address')
    return address
  }
  public disconnect = this.provider.disconnect
}

export default PhantomSolanaAdapter
