import React from 'react'

import { Col, Row, Typography, Space } from 'antd'
import ImageWrap from '@/components/systems/image-wrap/ImageWrap'

type RewardDetailsCardProps = {
  amount: number
  title: string
  label: string
  thumbnail: string
  description?: string
  type: 'info' | 'congrats'
}
const RewardDetailsCard = ({
  amount,
  title,
  label,
  thumbnail,
  description,
  type = 'info',
}: RewardDetailsCardProps) => {
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <ImageWrap src={thumbnail} alt={`Rewards ${label}`} />
      </Col>
      <Col span={24}>
        {type === 'congrats' && (
          <Space direction="vertical" size={6}>
            <Typography.Text
              type="success"
              style={{ fontWeight: 600, fontSize: 24 }}
            >
              Congratulations!
            </Typography.Text>

            <Typography.Text style={{ fontWeight: 300, fontSize: 16 }}>
              {`You've claimed ${amount} ${title}`}
            </Typography.Text>
          </Space>
        )}
        {type === 'info' && (
          <Space direction="vertical" size={6}>
            <Typography.Text
              type="success"
              style={{ fontWeight: 600, fontSize: 24 }}
            >
              Collect your {label}
            </Typography.Text>

            <Typography.Title
              style={{ textTransform: 'uppercase' }}
              level={4}
              className="gradient-text"
            >
              {amount} {title}
            </Typography.Title>

            {description && (
              <Typography.Paragraph
                type="secondary"
                ellipsis={{
                  rows: 2,
                  expandable: true,
                }}
              >
                {description}
              </Typography.Paragraph>
            )}
          </Space>
        )}
      </Col>
    </Row>
  )
}

export default RewardDetailsCard
