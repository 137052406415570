import { create } from 'zustand'

/**
 *  UI Store
 */

export type UiStore = {
  isOpenSider: boolean
  isOpenUserNav: boolean
  setIsOpenSider: (open: boolean) => void
  setIsOpenUserNav: (open: boolean) => void
}

const initialState = {
  isOpenSider: false,
  isOpenUserNav: false,
}

export const useUiStore = create<UiStore>((set) => ({
  ...initialState,
  setIsOpenSider: (open) => {
    set((state) => {
      return { ...state, isOpenSider: open }
    })
  },
  setIsOpenUserNav: (open) => {
    set((state) => {
      return { ...state, isOpenUserNav: open }
    })
  },
}))
