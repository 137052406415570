import { useCallback, useMemo, useState } from 'react'
import {
  useSignMessage,
  useDisconnect,
  useNetwork,
  useAccount,
  ConnectorAlreadyConnectedError,
} from 'wagmi'

import { notify } from '@/providers/notify.provider'

import PhantomSolanaAdapter from '@/libs/adapters/phantom.sol'
import Coin98SolanaAdapter from '@/libs/adapters/coin98.sol'
import { useUserStore } from '@/stores/user.store'
import { useMainStore } from '@/stores/main.store'
import useConnectors from './login/useConnectors'

import {
  challengeWallet,
  connectWallet as authConnectWalletApi,
  validateWallet,
} from '@/services/auth/walletAuth'
import { AuthType } from '@/services/auth/authType'

import AdapterInterface from '@/libs/adapters/adapter.interface'
import {
  isEthereumAddress,
  isRoninAddress,
  isSolanaAddress,
  shortenAddress,
} from '@/helpers'
import { setWalletType } from '@/helpers/runtime'
import { openInNewTab } from '@/helpers/common'
import { CONNECTING_RESTRICT_CHAINS, ChainID } from '@/constants'
import { IConnector } from '@/components/login'

import { useChainMap } from './useChainMap'
import { useRoninSignMessage } from './adapters/useCustomizedSignMessage'

export const useSolanaWalletProviders = () => {
  const providers: Record<string, AdapterInterface> = useMemo(
    () => ({
      phantom: new PhantomSolanaAdapter(),
      coin98: new Coin98SolanaAdapter(),
    }),
    [],
  )
  return providers
}

export const useConnectSolana = () => {
  const solanaProviders = useSolanaWalletProviders()

  let a8Address: string | undefined
  const { userProfile } = useUserStore()
  if (userProfile) a8Address = userProfile.walletAddress

  const onSwitchWallet = useMainStore((state) => state.onSwitchWallet)

  const onConnectSolana = useCallback(
    async (
      type: keyof typeof solanaProviders,
      onClose?: () => void,
      auto?: boolean,
    ) => {
      if (!a8Address && auto) return

      if (!a8Address)
        return notify.error({
          message:
            'Please add this Solana wallet to your A8 ID in the Profile section to execute this action',
        })

      try {
        const provider = solanaProviders[type]

        if (!provider.isInstalled) return window.open(provider.url, '_blank')
        await provider.connect()
        const walletAddress = await provider.getAddress()
        if (!isSolanaAddress(walletAddress) || a8Address !== walletAddress) {
          onSwitchWallet({
            chain: ChainID.Default,
            walletType: '',
            address: '',
          })
          return await provider.disconnect()
        }
        setWalletType(type)
        onSwitchWallet({
          chain: ChainID.Solana,
          walletType: type,
          address: walletAddress,
        })
      } finally {
        if (onClose) onClose()
      }
    },
    [a8Address, onSwitchWallet, solanaProviders],
  )

  return onConnectSolana
}

export const useConnectWagmi = () => {
  const { roninSignMessage } = useRoninSignMessage()
  const { chain } = useNetwork()
  const [authChallengeId, setAuthChallengeId] = useState('')
  const { updateProfile, connectWallet, authEntities } = useUserStore()
  const { disconnect } = useDisconnect()
  const onSwitchWallet = useMainStore((state) => state.onSwitchWallet)
  const { getChainId } = useChainMap()
  const {
    address: walletAddress,
    connector: currentConnector,
    isConnected,
  } = useAccount()

  const { signMessage } = useSignMessage({
    onError: () => {
      onSwitchWallet({ chain: ChainID.Default, walletType: '', address: '' })
      if (isConnected) disconnect()
    },
    async onSuccess(data) {
      await getAndSetToken(data)
    },
  })
  const { connect } = useConnectors({
    async onSuccess(data) {
      const { account, connector } = data
      const connectorId = connector?.id.toLowerCase()
      const address =
        (connectorId === 'roninwallet' ? 'ronin:' : '0x') + account.slice(2)

      await getSignature(address.toLowerCase())
      // Additional: Not support phantom yet
      // if (connectorId && connectorId !== 'phantom')
      //   onSwitchWallet({
      //     address: address.toLowerCase(),
      //     walletType: connectorId,
      //     chain: getChainId(chain?.id),
      //   })
    },
    onError(err) {
      if (err instanceof ConnectorAlreadyConnectedError && walletAddress) {
        const formatedAddress =
          currentConnector?.id === 'roninWallet'
            ? walletAddress.replace(/^0x/, 'ronin:').toLowerCase()
            : walletAddress.toLowerCase()
        return getSignature(formatedAddress)
      }

      notify.error({
        message: 'Please connect to an address',
      })
    },
  })

  const getAndSetToken = useCallback(
    async (
      signature: string,
      _authChallengeId?: string,
      _walletAddress?: string,
    ) => {
      const innerWalletAddress = _walletAddress || walletAddress
      if (!innerWalletAddress) return
      const connectorId = currentConnector?.id.toLowerCase()
      const formattedWalletAddress =
        connectorId === 'roninwallet'
          ? innerWalletAddress.replace(/^0x/, 'ronin:').toLowerCase()
          : innerWalletAddress.toLowerCase()
      const payload = {
        type:
          connectorId === 'roninwallet' ? AuthType.Ronin : AuthType.EVMChain,
        credential: {
          authChallengeId: _authChallengeId || authChallengeId,
          walletAddress: formattedWalletAddress,
          signedData: signature,
        },
      }
      const chainId = getChainId(chain?.id)

      const existed = await validateWallet(formattedWalletAddress)
      const userWallet = authEntities
        .filter((authEntity) =>
          [AuthType.EVMChain, AuthType.Ronin].includes(authEntity.type),
        )
        .find((authEntity) => {
          return authEntity.credential?.walletAddress === formattedWalletAddress
        })

      if (existed && !userWallet) {
        onSwitchWallet({
          chain: ChainID.Default,
          walletType: '',
          address: '',
        })
        return notify.error({
          message: `The chosen ${shortenAddress(
            formattedWalletAddress,
          )} wallet is already linked to another Eko ID. Please try with another wallet address.`,
        })
      }

      const roninAuthEntity = authEntities.find(
        (auth) => auth.type === AuthType.Ronin,
      )

      if (
        (existed && !CONNECTING_RESTRICT_CHAINS.includes(chainId)) ||
        roninAuthEntity?.credential?.walletAddress === formattedWalletAddress
      ) {
        connectWallet(formattedWalletAddress)
        updateProfile({})
        setWalletType(currentConnector?.name)
        onSwitchWallet({
          chain: chainId,
          walletType: `${currentConnector?.name}`,
          address: formattedWalletAddress,
        })
        return
      }

      try {
        await authConnectWalletApi(payload)
        connectWallet(formattedWalletAddress)
        updateProfile({})
        setWalletType(currentConnector?.name)
        onSwitchWallet({
          chain: chainId,
          walletType: `${currentConnector?.name}`,
          address: formattedWalletAddress,
        })

        if (!existed)
          notify.success({ message: 'You have successfully connected wallet' })
      } catch (error: any) {
        disconnect()
        notify.error({ message: `${error?.response?.data?.errorMessage}` })
      }
    },
    [
      authChallengeId,
      authEntities,
      chain?.id,
      connectWallet,
      currentConnector?.id,
      currentConnector?.name,
      disconnect,
      getChainId,
      onSwitchWallet,
      updateProfile,
      walletAddress,
    ],
  )

  const getSignature = useCallback(
    async (address: string) => {
      if (isEthereumAddress(address) || isRoninAddress(address)) {
        const { _id, message } = await challengeWallet(address)
        setAuthChallengeId(_id)
        if (currentConnector?.id.toLowerCase() === 'roninwallet') {
          try {
            const signedMessage = await roninSignMessage(message, address)
            await getAndSetToken(signedMessage, _id, address)
          } catch (err) {
            onSwitchWallet({
              chain: ChainID.Default,
              walletType: '',
              address: '',
            })
            if (isConnected) disconnect()
          }
          return
        }
        signMessage({ message })
      }
    },
    [
      currentConnector?.id,
      disconnect,
      getAndSetToken,
      isConnected,
      onSwitchWallet,
      roninSignMessage,
      signMessage,
    ],
  )

  const onConnectEVM = useCallback(
    async (connector: IConnector, onClose?: () => void) => {
      try {
        if (connector.isInstalled()) {
          connect({ connector })
        } else {
          openInNewTab(connector.installUrl)
        }
      } finally {
        if (onClose) onClose()
      }
    },
    [connect],
  )

  return onConnectEVM
}

export const useConnect = () => {
  const onConnectSolana = useConnectSolana()
  const onConnectWagmi = useConnectWagmi()

  const onConnect: Partial<
    Record<
      ChainID,
      (
        type: any,
        onClose?: () => void,
        auto?: boolean,
      ) => Promise<void | Window | null>
    >
  > = useMemo(
    () => ({
      [ChainID.BSC]: onConnectWagmi,
      [ChainID.Solana]: onConnectSolana,
    }),
    [onConnectWagmi, onConnectSolana],
  )

  return onConnect
}
