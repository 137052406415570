import React, { Fragment } from 'react'

import { Col, Row } from 'antd'
import LogIn from '@/components/login'
import DailyCheckinBanner from '@/components/daily-checkin/DailyCheckinBanner'
import DailyCheckinContainer from '@/components/daily-checkin/DailyCheckinContainer'

import { useIsLoggedIn } from '@/hooks/useIsLoggedIn'
import useCheckInRewards from '@/hooks/daily-checkin/useCheckInRewards'

import './index.scss'

const DailyCheckInView = () => {
  const isLogged = useIsLoggedIn()
  const { data: checkInData } = useCheckInRewards()

  return (
    <Col className="daily-checkin-container" id="daily-checkin-container">
      <Row gutter={[24, 34]}>
        {/* DAILY CHECKIN BANNER */}
        <Col span={24}>
          <DailyCheckinBanner />
        </Col>

        {/* DAILY CHECKIN CONTENTS */}
        <Col span={24} className="overlay-container">
          {!isLogged && (
            <div className="overlay-section">
              <LogIn />
            </div>
          )}
          {checkInData ? (
            <DailyCheckinContainer
              dailyRewards={checkInData.dailyRewards}
              streakRewards={checkInData.streakRewards}
            />
          ) : (
            // TODO: add empty state if required
            <Fragment />
          )}
        </Col>
      </Row>
    </Col>
  )
}

export default DailyCheckInView
