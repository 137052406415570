import {
  ArrowLeft,
  ArrowRight,
  DISCORD,
  FB,
  Person,
  REDDIT,
  TELE,
  TWT,
  YOUTUBE,
  GOOGLE,
  TWTX,
} from '@/assets/images/custom-icon'

export const CUSTOM_ICONS: Record<string, any> = {
  'arrow-double-left': ArrowLeft,
  'arrow-double-right': ArrowRight,
  'person-info': Person,
  'twitter-x': TWTX,
  telegram: TELE,
  facebook: FB,
  reddit: REDDIT,
  twitter: TWT,
  discord: DISCORD,
  youtube: YOUTUBE,
  google: GOOGLE,
}
