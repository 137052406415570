import { Avatar } from 'antd'
import { AvatarSize } from 'antd/es/avatar/AvatarContext'

import { ChainLogoMap } from '@/constants/chain'
import { ChainID, CommunityChainID } from '@/constants'

import './index.scss'

function ChainLogo({
  chainId,
  className,
  size = 24,
}: {
  chainId: CommunityChainID | ChainID
  className?: string
  size?: AvatarSize
}) {
  return (
    <Avatar
      alt="chain-logo"
      size={size}
      src={ChainLogoMap[chainId]}
      className={`chain-logo ${className}`}
    />
  )
}

export default ChainLogo
