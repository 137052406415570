import React, { useMemo } from 'react'
import { createGlobalState } from 'react-use'
import dayjs from 'dayjs'

import { Col, Row } from 'antd'
import Rules from '@/components/daily-checkin/Rules'
import DailyCheckinContentMobile from '@/components/daily-checkin/DailyCheckinContentMobile'
import DailyCheckinContentDesktop from '@/components/daily-checkin/DailyCheckinContentDesktop'

import useIsMobile from '@/hooks/useIsMobile'

import { getUTCWeekOfMonths } from '@/utils/dateUtils'
import { IDailyReward, IStreakReward } from '@/types/check-in.type'

type DailyCheckinContainerProps = {
  dailyRewards: IDailyReward[][]
  streakRewards: IStreakReward[]
}

export const useCompleteDailyCheckedIn = createGlobalState<boolean>(false)

export const DailyCheckinContainer = ({
  dailyRewards,
  streakRewards,
}: DailyCheckinContainerProps) => {
  const isMobile = useIsMobile()
  const [activeDailyRewards, activeStreakRewards] = useMemo(() => {
    const currentWeekOfMonth = getUTCWeekOfMonths(dayjs.utc())
    const weekOfMonthIndex = currentWeekOfMonth - 1
    return [dailyRewards[weekOfMonthIndex], streakRewards[weekOfMonthIndex]]
  }, [dailyRewards, streakRewards])

  return (
    <Row gutter={[16, 16]} className="daily-checkin__contents">
      {/* REWARDS */}
      <Col span={24}>
        {isMobile ? (
          <DailyCheckinContentMobile
            dailyRewards={activeDailyRewards}
            streakRewards={activeStreakRewards}
          />
        ) : (
          <DailyCheckinContentDesktop
            dailyRewards={activeDailyRewards}
            streakRewards={activeStreakRewards}
          />
        )}
      </Col>

      {/* BUTTON */}
      <Col span={24} style={{ textAlign: isMobile ? 'center' : 'left' }}>
        <Rules />
      </Col>
    </Row>
  )
}

export default DailyCheckinContainer
