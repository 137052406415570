import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { Button, Card, Col, Image, Row, Spin, Tooltip, Typography } from 'antd'
import SP3Modal from '@/components/modal'
import SP3Skeleton from '@/components/skeleton/SP3Skeleton'
import SwiperWrap from '@/components/swiper/SwiperWrap'
import RewardDetailsCard from '@/components/daily-checkin/RewardDetailsCard'
import SpaceVertical from '@/components/systems/SpaceVertical'
import AmountTag from '@/components/systems/tags/AmountTag'

import useIsMobile from '@/hooks/useIsMobile'
import { useBadgeById } from '@/hooks/badge/useBadge'
import useCheckIn from '@/hooks/daily-checkin/useCheckIn'
import { useCompleteDailyCheckedIn } from '@/components/daily-checkin/DailyCheckinContainer'

import { notify } from '@/providers/notify.provider'

import NoReward from '@/assets/images/daily-checkin/no-reward.svg'
import EP from '@/assets/images/token-logo/ep.webp'
import EPFullHD from '@/assets/images/token-logo/ep-hd.webp'
import { CheckMarkIcon } from '@/assets/images/icon/CheckMarkIcon'
import { XMarkIcon } from '@/assets/images/icon/XMarkIcon'
import LoadingSuccessIcon from '@/assets/images/icon/LoadingSuccessIcon'

import { IDailyReward } from '@/types/check-in.type'

export enum DailyRewardCardStatus {
  UNDEFINED = '',
  MISSED = 'missed',
  ACTIVE = 'active',
  CHECKED_IN = 'checked-in',
  CHECKED_IN_WITHOUT_REWARD = 'checked-in-without-reward',
  CLAIMED = 'claimed',
  UPCOMING = 'upcoming',
}

const DailyRewardCard = ({ date, reward }: IDailyReward) => {
  const isMobile = useIsMobile()
  const [, setCompleteDailyCheckedIn] = useCompleteDailyCheckedIn()
  const [open, setOpen] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const {
    loading,
    isChecked,
    canCheckIn,
    isClaimed,
    canClaim,
    onCheckIn,
    onClaimReward,
  } = useCheckIn(date, date, reward?._id)

  const { data: badgeDetail } = useBadgeById(reward?.rewardBadge?.badgeId || '')

  const badgeAmount = reward?.rewardBadge?.amount || 1

  const thumb = reward?.rewardCCP ? EP.src : badgeDetail?.thumbnail

  const [status, cardClassNameExpanded] = useMemo(() => {
    let status = DailyRewardCardStatus.UNDEFINED
    const todayInUTC = dayjs.utc().startOf('day')
    const checkDateInUTC = dayjs.utc(date).startOf('day')

    if (isChecked && !reward?._id) status = DailyRewardCardStatus.CHECKED_IN
    if (isChecked && reward?._id && !isClaimed)
      status = DailyRewardCardStatus.CHECKED_IN_WITHOUT_REWARD
    if (isChecked && isClaimed) status = DailyRewardCardStatus.CLAIMED
    if (!isChecked && checkDateInUTC.isBefore(todayInUTC))
      status = DailyRewardCardStatus.MISSED
    if (checkDateInUTC.isAfter(todayInUTC))
      status = DailyRewardCardStatus.UPCOMING

    let cls = status ? `checkin-reward-card--${status}` : ''
    if (canCheckIn || checkDateInUTC.isSame(todayInUTC))
      cls += ` checkin-reward-card--${DailyRewardCardStatus.ACTIVE}`

    return [status, cls]
  }, [canCheckIn, date, isChecked, isClaimed, reward?._id])

  // Show checkmark if checked and reward is claimed if available
  const showCheckmark = useMemo(() => {
    const eligibleStatus = [
      DailyRewardCardStatus.CLAIMED,
      DailyRewardCardStatus.CHECKED_IN,
    ].includes(status)

    return eligibleStatus && (reward?._id ? isClaimed : true)
  }, [isClaimed, reward?._id, status])

  const handleCheckIn = async () => {
    setIsChecking(true)

    try {
      if (isChecking) return

      // If already checked-in or claimed, do nothing
      if (isClaimed || (isChecked && !canClaim)) return

      // If not checked in, do the check-in and claim
      if (canCheckIn) await onCheckIn()
      await handleClaim()
      setCompleteDailyCheckedIn(true)
    } catch (error) {
      notify.error({ message: 'Something went wrong. Please try again!' })
    } finally {
      setIsChecking(false)
    }
  }

  const handleClaim = async () => {
    const enable = (canCheckIn || canClaim) && !loading
    if (!enable) return
    const claimed = await onClaimReward()

    if (reward && claimed) {
      setOpen(true)
    }
  }

  const tooltips = useMemo(() => {
    return (
      <SpaceVertical>
        {reward?.rewardCCP !== undefined && reward?.rewardCCP > 0 && (
          <Typography.Text>{`${reward.rewardCCP} EP`}</Typography.Text>
        )}
        {badgeDetail && (
          <Typography.Text>{`${badgeAmount} ${badgeDetail?.title}`}</Typography.Text>
        )}
        {!reward?.rewardCCP && !badgeDetail && (
          <Typography.Text>No Rewards</Typography.Text>
        )}
      </SpaceVertical>
    )
  }, [badgeAmount, badgeDetail, reward?.rewardCCP])

  return (
    <Tooltip
      title={tooltips}
      placement="bottom"
      mouseEnterDelay={0.5}
      getPopupContainer={() =>
        document.querySelector('.daily-checkin-wrap-modal') as HTMLElement
      }
    >
      <Card
        className={`checkin-reward-card ${cardClassNameExpanded}`}
        onClick={(e) => {
          e.stopPropagation()
          handleCheckIn()
        }}
      >
        <Row className="checkin-reward-card__contents" gutter={[0, 8]}>
          <Col span={24} className="col-align-center">
            <Typography.Text className="checkin-reward-card__contents--title">
              {dayjs(date).format('DD')}
            </Typography.Text>
          </Col>
          <Col
            span={24}
            className="col-align-end overlay-container checkin-reward-card__contents--thumb-container"
          >
            <Spin
              size="large"
              spinning={loading}
              className="centered-elem"
              indicator={<LoadingSuccessIcon size={32} />}
            >
              {showCheckmark && (
                <div className="overlay">
                  <CheckMarkIcon />
                </div>
              )}
              {status === DailyRewardCardStatus.MISSED && (
                <div className="overlay">
                  <XMarkIcon color="#404549" />
                </div>
              )}
              <Image
                preview={false}
                src={thumb || NoReward.src}
                alt="Daily Reward"
                className="checkin-reward-card__contents--thumb"
                placeholder={thumb && <SP3Skeleton active width={'50%'} />}
                fallback={NoReward.src}
              />
            </Spin>
            {!reward?.rewardCCP &&
              reward?.rewardBadge &&
              reward.rewardBadge.amount > 1 && (
                <AmountTag
                  tagName={`x${reward.rewardBadge.amount}`}
                  position="absolute"
                  offset={[0.5, 0.5]}
                />
              )}
          </Col>
        </Row>

        {/* Modal rewards */}
        <SP3Modal
          width={isMobile ? 316 : 340}
          type="primary"
          open={open}
          parentId="daily-checkin-container"
          onCancel={() => setOpen(false)}
        >
          <Row
            gutter={[0, 24]}
            justify="center"
            style={{ textAlign: 'center' }}
          >
            <Col span={24}>
              <SwiperWrap
                identifierPrefixCls={`daily-reward-sw-${reward?._id}`}
                positionButton={{ x: 2, y: 164 }}
                items={[
                  reward?.rewardCCP && (
                    <RewardDetailsCard
                      type="congrats"
                      amount={reward.rewardCCP}
                      title="EP"
                      thumbnail={EPFullHD.src}
                      label="point"
                    />
                  ),
                  badgeDetail && (
                    <RewardDetailsCard
                      type="congrats"
                      amount={badgeAmount}
                      title={badgeAmount > 1 ? 'badges' : 'badge'}
                      thumbnail={badgeDetail.thumbnail}
                      label="gift"
                    />
                  ),
                ]}
              />
            </Col>
            <Col span={24}>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setOpen(false)
                }}
                style={{ width: 160 }}
                type="primary"
              >
                Get!
              </Button>
            </Col>
          </Row>
        </SP3Modal>
      </Card>
    </Tooltip>
  )
}

export default DailyRewardCard
