import DailyCheckinBg from '@/assets/images/daily-checkin/daily-checkin-bg.png'

import { AdsData, AdsType } from '@/types/ads.type'

export const ADS_DEFAULT: AdsData = {
  _id: '',
  image: DailyCheckinBg.src,
  url: '',
  isPublished: true,
  startedAt: new Date(),
  endedAt: new Date(),
  type: AdsType.DailyCheckin,
  priority: 1,
}
