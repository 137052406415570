import React, {
  CSSProperties,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import clsx from 'clsx'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import ButtonCircle from '@/components/base-button/ButtonCircle'

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

import {
  DEFAULT_NEXT_CLS,
  DEFAULT_PREV_CLS,
  PAGINATION_CLS,
} from '@/constants/swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './index.scss'

type SwiperWrapProps = {
  showPagination?: boolean
  showNavigationButtons?: boolean
  showDisabledNavBtn?: boolean
  items: (ReactNode | null | undefined)[]
  btnControlSize?: 'sm' | 'md' | 'lg' | 'mg'
  wrapperCls?: string
  pagination?: boolean
  paginationType?: 'bullets' | 'fraction'
  paginationPosition?: 'inner' | 'outer'
  positionButton?: {
    x?: string | number
    y?: string | number
  }
  style?: CSSProperties
  paginationStyle?: CSSProperties
  activeIndex?: number

  // should be unique if there are multiple swiper in a page that not related
  identifierPrefixCls: string
  componentDidMount?: () => void
  onCurrentSlideIndex?: (index: number) => void
}

type SwipePaginationProps = {
  prefixCls: string
  paginationStyle?: CSSProperties
}

const SwipePagination = ({
  prefixCls,
  paginationStyle,
}: SwipePaginationProps) => {
  return (
    <div className={`${prefixCls}-${PAGINATION_CLS}`} style={paginationStyle} />
  )
}

function SwiperWrap({
  items,
  showPagination = true,
  showNavigationButtons = true,
  showDisabledNavBtn = false,
  wrapperCls = '',
  btnControlSize = 'md',
  pagination = true,
  paginationType = 'bullets',
  paginationPosition = 'outer',
  positionButton = {
    x: -10,
    y: '50%',
  },
  style,
  paginationStyle,
  activeIndex = 0,
  identifierPrefixCls = 'sp3',
  componentDidMount,
  onCurrentSlideIndex,
}: SwiperWrapProps) {
  const divRef = useRef(null)

  const validItems = useMemo(() => items.filter((e) => !!e), [items])

  useEffect(() => {
    if (divRef.current && !!componentDidMount) {
      // Set timeout to prevent flickering
      setTimeout(() => {
        componentDidMount()
      }, 1200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef])

  if (!validItems.length) return null

  return (
    <Fragment>
      <div
        className={`sp3-swiper-container sp3-swiper-pagination-${paginationPosition} ${wrapperCls}`}
      >
        <Swiper
          ref={!componentDidMount ? undefined : divRef}
          initialSlide={activeIndex}
          centeredSlides={true}
          navigation={{
            prevEl: `.${identifierPrefixCls}-${DEFAULT_PREV_CLS}`,
            nextEl: `.${identifierPrefixCls}-${DEFAULT_NEXT_CLS}`,
          }}
          slidesPerView={1}
          pagination={{
            el: `.${identifierPrefixCls}-${PAGINATION_CLS}`,
            clickable: true,
            enabled: pagination,
            type: paginationType,
          }}
          modules={[Navigation, Pagination]}
          className={`${identifierPrefixCls}-inner`}
          style={style}
          onSlideChange={(swiper) => {
            if (onCurrentSlideIndex) {
              onCurrentSlideIndex(swiper.activeIndex)
            }
          }}
        >
          {validItems.map((item, index) => (
            <SwiperSlide key={index}>{item}</SwiperSlide>
          ))}
        </Swiper>

        {showNavigationButtons && (
          <Fragment>
            <ButtonCircle
              wrapperCls={clsx(
                `${identifierPrefixCls}-${DEFAULT_PREV_CLS} btn-navigate-swiper--${btnControlSize}`,
                showDisabledNavBtn && 'show-disabled-nav-btn',
              )}
              style={{
                left: positionButton.x || -10,
                top: positionButton.y || '50%',
              }}
              icon={<ArrowLeftOutlined />}
            />

            <ButtonCircle
              wrapperCls={clsx(
                `${identifierPrefixCls}-${DEFAULT_NEXT_CLS} btn-navigate-swiper--${btnControlSize}`,
                showDisabledNavBtn && 'show-disabled-nav-btn',
              )}
              style={{
                right: positionButton.x || -10,
                top: positionButton.y || '50%',
              }}
              icon={<ArrowRightOutlined />}
            />
          </Fragment>
        )}

        {showPagination && (
          <SwipePagination
            prefixCls={identifierPrefixCls}
            paginationStyle={paginationStyle}
          />
        )}
      </div>
    </Fragment>
  )
}

export default SwiperWrap
