import React from 'react'
import Image from 'next/image'

import './index.scss'

type ImageWrapProps = {
  src: string
  alt: string
  style?: React.CSSProperties
}
const ImageWrap = ({ src, alt, style }: ImageWrapProps) => {
  return (
    <div className="sp3-image-wrap">
      <Image
        fill
        src={src}
        alt={alt ?? 'SP3 Image Wrap'}
        style={{ borderRadius: 8, ...style }}
      />
    </div>
  )
}

export default ImageWrap
