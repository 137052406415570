import { ReactNode, useMemo } from 'react'

import { theme, Modal } from 'antd'
import IonIcon from '@/components/systems/ion-icon'

import './index.scss'

type SP3ModalProps = {
  open: boolean
  onCancel: () => void
  children: ReactNode
  zIndex?: number
  width?: number
  height?: number
  type?: 'default' | 'primary' | 'secondary'
  background?: string
  btnClosePosition?: 'default' | 'bottom-outer' | 'top-right-outer'
  parentId?: string
  wrapClassName?: string
}
const SP3Modal = ({
  open,
  onCancel,
  children,
  zIndex,
  width,
  height,
  type = 'default',
  background,
  btnClosePosition = 'default',
  parentId = '__next',
  wrapClassName,
}: SP3ModalProps) => {
  const { useToken } = theme
  const { token } = useToken()

  const modalBackground = useMemo(() => {
    return {
      background: background
        ? `url(${background}) no-repeat`
        : token.colorBgLayout,
    }
  }, [background, token.colorBgLayout])

  return (
    <Modal
      open={open}
      onCancel={(e) => {
        e.stopPropagation()
        onCancel()
      }}
      footer={null}
      closeIcon={<IonIcon name="close-outline" />}
      width={width}
      className={`sp3-modal sp3-modal--${type} sp3-modal--close-${btnClosePosition}`}
      getContainer={() => document.getElementById(`${parentId}`) as HTMLElement}
      zIndex={zIndex}
      centered
      forceRender
      destroyOnClose
      style={{ ...modalBackground, height }}
      wrapClassName={wrapClassName}
    >
      {children}
    </Modal>
  )
}

export default SP3Modal
