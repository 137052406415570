import { Data } from './common.type'

export interface IAds {
  image: string

  url: string

  isPublished: boolean

  startedAt: Date

  endedAt: Date

  type: AdsType

  priority: number
}

export enum AdsType {
  DailyCheckin = 'ADS_TYPE::DAILY_CHECKIN',
}

export type AdsData = Data<IAds>
