import { CSSProperties, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Button } from 'antd'
import Icon from '@ant-design/icons'
import SP3Modal from '@/components/modal'
import DailyCheckInView from '@/views/daily-checkin'

import useIsMobile from '@/hooks/useIsMobile'
import useCheckIn from '@/hooks/daily-checkin/useCheckIn'
import { useIsLoggedIn } from '@/hooks/useIsLoggedIn'
import { useUiStore } from '@/stores/ui.store'

import { DailyCheckinIcon } from '@/assets/images/icon'

import './index.scss'

type DailyCheckinProps = {
  style?: CSSProperties
}

function DailyCheckin({ style }: DailyCheckinProps) {
  const isMobile = useIsMobile()
  const isLogged = useIsLoggedIn()
  const { setIsOpenSider } = useUiStore()
  const [open, setOpen] = useState(false)
  const { canCheckIn, isFetching } = useCheckIn(
    dayjs.utc().toISOString(),
    dayjs.utc().toISOString(),
  )

  useEffect(() => {
    if (canCheckIn && isLogged && !isFetching) setOpen(true)
  }, [canCheckIn, isFetching, isLogged])

  return (
    <>
      <Button
        type="text"
        icon={<Icon component={DailyCheckinIcon} />}
        className="daily-checkin--main-btn"
        onClick={() => {
          if (isMobile) setIsOpenSider(false)
          setOpen(true)
        }}
        style={{ ...style }}
      >
        Daily Check-in
      </Button>
      <SP3Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={isMobile ? 545 : 1112}
        btnClosePosition={isMobile ? 'bottom-outer' : 'top-right-outer'}
        wrapClassName="daily-checkin-wrap-modal"
        zIndex={999}
      >
        <DailyCheckInView />
      </SP3Modal>
    </>
  )
}

export default DailyCheckin
