import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import dayjs from 'dayjs'

import { Button, Card, Col, Image, Row, Spin, Typography } from 'antd'
import SP3Skeleton from '@/components/skeleton/SP3Skeleton'
import SwiperWrap from '@/components/swiper/SwiperWrap'
import SP3Modal from '@/components/modal'
import RewardDetailsCard from '@/components/daily-checkin/RewardDetailsCard'
import AmountTag from '@/components/systems/tags/AmountTag'

import useIsMobile from '@/hooks/useIsMobile'
import { useBadgeById } from '@/hooks/badge/useBadge'
import useCheckIn from '@/hooks/daily-checkin/useCheckIn'
import { useCompleteDailyCheckedIn } from '@/components/daily-checkin/DailyCheckinContainer'

import { IStreakReward } from '@/types/check-in.type'
import EP from '@/assets/images/token-logo/ep-hd.webp'
import { CheckMarkIcon } from '@/assets/images/icon'
import NoReward from '@/assets/images/daily-checkin/no-reward.svg'
import LoadingSuccessIcon from '@/assets/images/icon/LoadingSuccessIcon'
import { YMD } from '@/constants'

type StreakRewardCardProps = {
  title: string
  streakReward: IStreakReward
  style?: CSSProperties
}

const StreakRewardCard = ({
  title,
  streakReward,
  style,
}: StreakRewardCardProps) => {
  const isMobile = useIsMobile()
  const [completeDailyCheckedIn] = useCompleteDailyCheckedIn()
  const eventState = useRef('waiting')
  const [open, setOpen] = useState(false)
  const { fromDate, toDate, reward } = streakReward
  const { canClaim, isClaimed, loading, onClaimReward } = useCheckIn(
    fromDate,
    toDate,
    reward?._id,
  )
  const { data: badgeDetail } = useBadgeById(reward?.rewardBadge?.badgeId || '')

  const badgeAmount = reward?.rewardBadge?.amount || 1

  const handleViewDetails = () => {
    setOpen(true)
  }

  const isLastDayOfStreak = useMemo(() => {
    return (
      dayjs.utc().startOf('day').format(YMD) ===
      dayjs.utc(toDate).startOf('day').format(YMD)
    )
  }, [toDate])
  const rewardThumbs = useMemo(() => {
    const thumbs: string[] = []
    if (reward?.rewardCCP) thumbs.push(EP.src)
    if (badgeDetail) thumbs.push(badgeDetail.thumbnail)
    if (!badgeDetail && !reward?.rewardCCP) thumbs.push(NoReward.src)

    return thumbs.map((thumb, idx) => {
      return (
        <Fragment key={idx}>
          <Image
            preview={false}
            onClick={() => {
              if (thumb === NoReward.src) return
              handleViewDetails()
            }}
            src={thumb}
            alt="Reward"
            className="checkin-reward-card__contents--thumb"
            placeholder={reward && <SP3Skeleton active width="50%" />}
            fallback={NoReward.src}
          />

          {thumb !== EP.src &&
            reward?.rewardBadge &&
            reward.rewardBadge.amount > 1 && (
              <AmountTag
                tagName={`x${reward.rewardBadge.amount}`}
                position="absolute"
                offset={[0.5, 0.5]}
              />
            )}
        </Fragment>
      )
    })
  }, [badgeDetail, reward])

  const handleClaimStreakRewards = useCallback(async () => {
    if (isClaimed || !canClaim) return

    const claimed = await onClaimReward()
    if (reward && claimed) setOpen(true)

    return claimed
  }, [canClaim, isClaimed, onClaimReward, reward])

  useEffect(() => {
    // auto claim if eligible
    if (
      isLastDayOfStreak &&
      completeDailyCheckedIn &&
      !loading &&
      eventState.current === 'waiting'
    ) {
      eventState.current = 'triggered' // prevent auto claim again if triggered before
      handleClaimStreakRewards()
    }
  }, [
    completeDailyCheckedIn,
    handleClaimStreakRewards,
    isLastDayOfStreak,
    loading,
  ])

  return (
    <Card
      className={`checkin-reward-card ${
        canClaim && 'checkin-reward-card--eligible'
      }`}
      style={{ ...style }}
    >
      <Row
        className="checkin-reward-card__contents"
        justify="space-between"
        gutter={[0, 8]}
      >
        <Col span={24} className="col-align-center">
          <Typography.Text className="checkin-reward-card__contents--streak-title">
            {title.replace(/\s/g, '\n')}
          </Typography.Text>
        </Col>
        <Col
          span={24}
          className="col-align-end overlay-container checkin-reward-card__contents--thumb-container"
        >
          <Spin
            size="large"
            spinning={loading}
            className="centered-elem"
            indicator={<LoadingSuccessIcon size={32} />}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: loading ? 2 : -1,
            }}
          />
          {isClaimed && (
            <div className="overlay">
              <CheckMarkIcon />
            </div>
          )}
          <SwiperWrap
            identifierPrefixCls="streak-reward-thumbs-sw"
            btnControlSize="sm"
            positionButton={{ x: 2, y: '50%' }}
            paginationPosition="inner"
            items={rewardThumbs}
          />
        </Col>
      </Row>
      <SP3Modal
        width={isMobile ? 316 : 340}
        type="primary"
        open={open}
        parentId="daily-checkin-container"
        onCancel={() => setOpen(false)}
      >
        <Row gutter={[0, 24]} justify="center" style={{ textAlign: 'center' }}>
          <Col span={24}>
            <SwiperWrap
              identifierPrefixCls="streak-reward-details-sw"
              positionButton={{ x: 2, y: 164 }}
              items={[
                reward?.rewardCCP && (
                  <RewardDetailsCard
                    type="info"
                    amount={reward.rewardCCP}
                    title="EP"
                    thumbnail={EP.src}
                    label="point"
                  />
                ),
                badgeDetail && (
                  <RewardDetailsCard
                    type="info"
                    amount={badgeAmount}
                    title={badgeAmount > 1 ? 'Badges' : 'Badge'}
                    thumbnail={badgeDetail.thumbnail}
                    description={badgeDetail.description}
                    label="gift"
                  />
                ),
              ]}
            />
          </Col>
          <Col span={24}>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              style={{ width: 160 }}
              type="primary"
            >
              Get!
            </Button>
          </Col>
        </Row>
      </SP3Modal>
    </Card>
  )
}

export default StreakRewardCard
