import { CSSProperties, ReactNode } from 'react'
import { Button, ButtonProps } from 'antd'

import './index.scss'

type ButtonCircleProps = {
  children?: ReactNode
  icon?: ReactNode
  wrapperCls?: string
  style?: CSSProperties
} & Omit<ButtonProps, 'className'>
// NOTE: remove className to prevent override from current component

function ButtonCircle({
  children,
  icon,
  wrapperCls = '',
  style,
  ...rest
}: ButtonCircleProps) {
  return (
    <Button
      type="text"
      shape="circle"
      icon={icon}
      className={`btn-circle-primary ${wrapperCls}`}
      style={style}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default ButtonCircle
