import { useQuery } from '@tanstack/react-query'

import { AdsService } from '@/services/ads'

export const useAds = () => {
  return useQuery({
    queryKey: ['GET_ADS'],
    queryFn: () => AdsService.getAdsList(),
  })
}
