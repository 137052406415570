import { useState, useEffect } from 'react'

import { useUserStore } from '@/stores/user.store'

import DefaultAvatar from '@/assets/images/profile/avatar-default.png'

const useAvatar = () => {
  const userProfile = useUserStore((state) => state.userProfile)
  const [avatar, setAvatar] = useState(DefaultAvatar.src)

  const onError = () => {
    setAvatar(DefaultAvatar.src)
    return true
  }

  useEffect(() => {
    setAvatar(userProfile?.avatar ? userProfile.avatar : DefaultAvatar.src)
  }, [userProfile])

  return { avatar, onError }
}

export default useAvatar
