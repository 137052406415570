import ArrowLeft from './arrow-double-left.svg'
import ArrowRight from './arrow-double-right.svg'
import Person from './ico-person.svg'
import FB from './socials/fb.svg'
import TELE from './socials/tele.svg'
import DISCORD from './socials/discord.svg'
import REDDIT from './socials/reddit.svg'
import TWT from './socials/twt.svg'
import YOUTUBE from './socials/youtube.svg'
import GOOGLE from './socials/google.svg'
import TWTX from './socials/twt-x.svg'

export {
  ArrowLeft,
  ArrowRight,
  Person,
  FB,
  TELE,
  TWT,
  DISCORD,
  REDDIT,
  YOUTUBE,
  GOOGLE,
  TWTX,
}
