import { useCallback } from 'react'
import { useDisconnect } from 'wagmi'

import { ChainID } from '@/constants'

import { notify } from '@/providers/notify.provider'
import { useSolanaWalletProviders } from './useWalletProviders'
import { removeStorage, WALLET_TYPE_KEY } from '@/helpers/runtime'
import { useMainStore } from '@/stores/main.store'

export const useDisconnectClient = () => {
  const { disconnect } = useDisconnect()
  const { onSwitchWallet, wallet } = useMainStore((state) => state)

  const solProviders = useSolanaWalletProviders()

  const onDisconnect = useCallback(() => {
    onSwitchWallet({ chain: ChainID.Default, walletType: '', address: '' })
    removeStorage([WALLET_TYPE_KEY])
    try {
      if (wallet.chain === ChainID.Solana)
        return solProviders[wallet.walletType].disconnect()
      return disconnect()
    } catch (e: any) {
      notify.error({
        message: e?.message ?? 'Something went wrong. Please try again later!',
      })
    }
  }, [
    disconnect,
    onSwitchWallet,
    solProviders,
    wallet.chain,
    wallet.walletType,
  ])

  return onDisconnect
}
