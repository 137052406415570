type XMarkIconProps = {
  color?: string
}

export const XMarkIcon = ({ color }: XMarkIconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10576_6140)">
      <path
        d="M29.5815 2.2124L2.71148 29.0825"
        stroke={color || 'currentColor'}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M29.5815 29.0823L3.41859 2.91932"
        stroke={color || 'currentColor'}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10576_6140">
        <rect width="32" height="32" fill={color || 'currentColor'} />
      </clipPath>
    </defs>
  </svg>
)
