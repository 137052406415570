import { useMemo } from 'react'
import dayjs from 'dayjs'

import { Row } from 'antd'

import SwiperSlides from '../swiper/SwiperSlides'
import DailyRewardCard from '@/components/daily-checkin/DailyRewardCard'
import StreakRewardCard from '@/components/daily-checkin/StreakRewardCard'

import { IActiveDailyCheckIn } from '@/types/check-in.type'

const DailyCheckinContentMobile = ({
  dailyRewards,
  streakRewards,
}: IActiveDailyCheckIn) => {
  const listItems = useMemo(() => {
    const listDailyRewardItems = dailyRewards.map((reward, index) => {
      return <DailyRewardCard {...reward} key={index} />
    })
    const streakItem = (
      <StreakRewardCard title="Streak Reward" streakReward={streakRewards} />
    )
    return [...listDailyRewardItems, streakItem]
  }, [dailyRewards, streakRewards])

  const currentDateIndex = useMemo(() => {
    const currentDate = dayjs().get('date')
    return dailyRewards.findIndex(
      (reward) => dayjs(reward.date).get('date') === currentDate,
    )
  }, [dailyRewards])

  return (
    <Row
      gutter={[16, 32]}
      align="bottom"
      justify="space-between"
      className="daily-checkin__contents-mobile"
    >
      <SwiperSlides
        items={listItems}
        slidesPerView={3}
        pagination={false}
        showNavigationButtons={false}
        isLoop={false}
        initialSlideIndex={currentDateIndex - 1}
        identifierPrefixCls="swiper-checkin"
      />
    </Row>
  )
}

export default DailyCheckinContentMobile
