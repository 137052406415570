import { useQuery } from '@tanstack/react-query'

import { missionApi } from '@/services/base-axios/missionApi'
import { CACHE_TIME } from '@/constants'
import { IDailyReward, IStreakReward } from '@/types/check-in.type'

const fetchCheckInRewards = async () => {
  const { data } = await missionApi.get<{
    dailyRewards: IDailyReward[][]
    streakRewards: IStreakReward[]
  }>('check-in/reward')
  return data
}

const useCheckInRewards = () => {
  return useQuery(['DAILY_CHECK_IN_REWARDS'], fetchCheckInRewards, {
    staleTime: CACHE_TIME,
  })
}

export default useCheckInRewards
