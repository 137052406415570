import React, { CSSProperties, useCallback } from 'react'
import { useDisconnect as useWagmiDisconnect } from 'wagmi'

import { Button, Typography } from 'antd'
import IonIcon from './ion-icon'

import { useUserStore } from '@/stores/user.store'
import { useMainStore } from '@/stores/main.store'
import { ChainID } from '@/constants'

type LogoutProps = {
  style?: CSSProperties
  block?: boolean
}

function Logout({ style, block = false }: LogoutProps) {
  const logout = useUserStore((state) => state.logout)
  const onSwitchWallet = useMainStore((state) => state.onSwitchWallet)
  const { disconnect: wagmiDisconnect } = useWagmiDisconnect()

  const onLogout = useCallback(() => {
    wagmiDisconnect()
    logout()
    onSwitchWallet({ chain: ChainID.Default, walletType: '', address: '' })
  }, [logout, onSwitchWallet, wagmiDisconnect])

  return (
    <Button
      onClick={onLogout}
      icon={
        <IonIcon
          name="log-out-outline"
          style={{ fontSize: 24, color: '#FF2424' }}
        />
      }
      type="text"
      style={{ height: 48, display: 'flex', alignItems: 'center', ...style }}
      block={block}
    >
      <Typography.Text style={{ fontSize: 16 }} type="danger">
        Logout
      </Typography.Text>
    </Button>
  )
}

export default Logout
