import { CSSProperties, FC } from 'react'
import { Image, Skeleton } from 'antd'

import SP3Placeholder from '@/assets/images/space3/sp3-placeholder.svg'

import './index.scss'

interface SP3SkeletonProps {
  wrapperCls?: string
  thumb?: string
  active?: boolean
  width?: string | number | undefined
  style?: CSSProperties
}

const SP3Skeleton: FC<SP3SkeletonProps> = ({
  wrapperCls = '',
  thumb = SP3Placeholder.src,
  active,
  width = 94,
  style,
}) => (
  <Skeleton.Node
    active={active || undefined}
    className={`sp3-skeleton ${wrapperCls}`}
    style={style}
  >
    <Image src={thumb} preview={false} width={width} alt="SP3 Skeleton" />
  </Skeleton.Node>
)

export default SP3Skeleton
