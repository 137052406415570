import React from 'react'

import { Col, Image, Row } from 'antd'

import LinkExternal from '../systems/link-external'

import { AdsData } from '@/types/ads.type'

type DailyCheckinContentBannerProps = {
  adsDetails: AdsData
}

function DailyCheckinContentBanner({
  adsDetails,
}: DailyCheckinContentBannerProps) {
  if (!adsDetails.url) {
    return (
      <Row className="daily-checkin__banner-item">
        <Col span={24} className="h-full">
          <Image
            src={adsDetails.image}
            preview={false}
            alt="Daily Reward Banner"
          />
        </Col>
      </Row>
    )
  }

  return (
    <Row className="daily-checkin__banner-item">
      <Col span={24} className="h-full">
        <LinkExternal href={adsDetails.url} className="h-full">
          <Image
            src={adsDetails.image}
            preview={false}
            alt="Daily Reward Banner"
          />
        </LinkExternal>
      </Col>
    </Row>
  )
}

export default DailyCheckinContentBanner
