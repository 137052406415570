import { useQuery } from '@tanstack/react-query'

import { useIsLoggedIn } from '@/hooks/useIsLoggedIn'

import { CreditPointService } from '@/services/creditPoint'

export const DUMMY_CREDIT_POINT = {
  _id: '',
  balanceExpiringSoon: 0,
  expirationDate: 0,
  totalBalance: 0,
  totalEarn: 0,
  expired: 0,
  usedCCP: 0,
}

export const useCreditBalance = () => {
  const isLoggedIn = useIsLoggedIn()

  const {
    data: creditData,
    isLoading,
    refetch: getBalance,
    ...props
  } = useQuery({
    queryKey: ['credit-balance', isLoggedIn],
    queryFn: async () => await CreditPointService.getBalance(),
    enabled: isLoggedIn,
  })

  return {
    creditData: creditData ?? DUMMY_CREDIT_POINT,
    isLoading,
    getBalance,
    ...props,
  }
}
