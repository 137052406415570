import React, { Fragment, useState } from 'react'

import { Button, Col, Row, Typography } from 'antd'
import SP3Modal from '@/components/modal'
import SpaceVertical from '@/components/systems/SpaceVertical'

const Rules = () => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <Button
        type="text"
        className="daily-checkin__contents--btn"
        onClick={() => setOpen(true)}
      >
        Rules
      </Button>
      <SP3Modal
        type="primary"
        width={696}
        open={open}
        parentId="daily-checkin-container"
        onCancel={() => setOpen(false)}
      >
        <Row style={{ padding: 12 }} gutter={[24, 24]}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Typography.Title type="success" level={2}>
              Streak Rewards Rules
            </Typography.Title>
          </Col>
          <Col span={24}>
            <SpaceVertical>
              <Typography.Text>
                The Space3 Daily Check-in is a system that awards users for
                regularly visiting the site. To maximize your benefits,
                consumers should be aware of the following:
              </Typography.Text>
              <SpaceVertical size={4} style={{ paddingLeft: 8 }}>
                <Typography.Text>
                  - You can only check in once per day.
                </Typography.Text>
                <Typography.Text>
                  - The check-in status resets everyday at 07:00 (GMT+7).
                </Typography.Text>
                <Typography.Text>
                  - To qualify for the 7-day Streak reward, you must check in
                  every day for 7 consecutive days. If you miss a day during
                  that 7-day period, you will not be eligible for this Streak
                  reward. However, you can still participate in the next streak
                  if 7 days of that streak are checked in entirely.
                </Typography.Text>
              </SpaceVertical>

              <Typography.Text>Seize your opportunities!</Typography.Text>
            </SpaceVertical>
          </Col>
        </Row>
      </SP3Modal>
    </Fragment>
  )
}

export default Rules
