import { CreditPointBalance } from '@/types/credit-point.type'

import configs from '@/configs'

import { ancient8Api } from '../base-axios/ancient8Api'

const NAME = 'credit-point'
const PATHS = {
  balance: '/balance',
  subCCP: '/sub-ccp',
  syncDiscord: '/sync-discord',
}

class CreditPoint {
  index: string = configs.api.missionApi + NAME
  async getBalance(): Promise<CreditPointBalance> {
    const { data } = await ancient8Api.get(this.index + PATHS.balance)
    return data
  }
  async syncFromDiscord(): Promise<CreditPointBalance> {
    const { data } = await ancient8Api.get(this.index + PATHS.syncDiscord)
    return data
  }
}

export const CreditPointService = new CreditPoint()
