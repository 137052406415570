import { marketplaceApi } from '../base-axios/marketplaceApi'

import { AdsData } from '@/types/ads.type'

const ADS_CONTROLLER = '/ads'

class Ads {
  async getAdsList() {
    const { data } = await marketplaceApi.get<AdsData[]>(ADS_CONTROLLER)

    return data
  }
}

export const AdsService = new Ads()
