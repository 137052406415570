import { StaticImageData } from 'next/image'

export enum Chain {
  solana = 'solana',
  evm = 'evm',
}

interface AdapterInterface {
  name: string
  chain: Chain
  icon: StaticImageData
  color: string
  url: string
  isInstalled: boolean
  connect: () => Promise<void>
  install: () => void
  signMessage: (msg: string) => Promise<string>
  getAddress: () => Promise<string>
  disconnect: () => void
}

export default AdapterInterface
