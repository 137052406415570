import React from 'react'

import { Col, Row } from 'antd'
import DailyRewardCard from '@/components/daily-checkin/DailyRewardCard'
import StreakRewardCard from '@/components/daily-checkin/StreakRewardCard'

import { IActiveDailyCheckIn } from '@/types/check-in.type'

const DailyCheckinContentDesktop = ({
  dailyRewards,
  streakRewards,
}: IActiveDailyCheckIn) => {
  return (
    <Row
      gutter={[16, 16]}
      align="bottom"
      wrap={false}
      justify="space-between"
      className="daily-checkin__contents-desktop"
    >
      {dailyRewards.map((reward, index) => (
        <Col
          flex="auto"
          key={index}
          className="daily-checkin__contents--daily-reward"
        >
          <DailyRewardCard {...reward} />
        </Col>
      ))}
      <Col flex="auto" className="daily-checkin__contents--daily-reward">
        <StreakRewardCard title="Streak Reward" streakReward={streakRewards} />
      </Col>
    </Row>
  )
}

export default DailyCheckinContentDesktop
