import React, { CSSProperties, useCallback } from 'react'

import { Button, Typography } from 'antd'

import IonIcon from './ion-icon'

import { useDisconnectClient } from '@/hooks/useDisconnectClient'

type DisconnectProps = {
  style?: CSSProperties
  block?: boolean
}

function Disconnect({ style, block = false }: DisconnectProps) {
  const disconnect = useDisconnectClient()

  const onDisconnect = useCallback(() => {
    disconnect()
  }, [disconnect])

  return (
    <Button
      icon={<IonIcon name="wallet-outline" style={{ fontSize: 24 }} />}
      type="text"
      style={{ height: 48, display: 'flex', alignItems: 'center', ...style }}
      onClick={onDisconnect}
      block={block}
    >
      <Typography.Text style={{ fontSize: 16 }}>
        Disconnect Wallet
      </Typography.Text>
    </Button>
  )
}

export default Disconnect
