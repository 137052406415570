import { useQuery } from '@tanstack/react-query'
import { BadgeService } from '@/services/badge'
import { FindBadgeDto, IBadge } from '@/types/badge.type'
import { FindAllData } from '@/types'

export const fetchBadgeById = async (id: string): Promise<IBadge> => {
  return await BadgeService.getById(id)
}

export const fetchBadgeByIds = async (
  ids: string[],
): Promise<FindAllData<IBadge>> => {
  return await BadgeService.getByIds(ids)
}

export const fetchBadge = async (
  params: FindBadgeDto,
): Promise<{ data: { data: IBadge[]; total: number } }> => {
  return await BadgeService.find(params)
}

export const useBadgeById = (id: string) => {
  return useQuery({
    queryKey: ['getBadgeById', id],
    queryFn: () => fetchBadgeById(id),
    enabled: !!id,
  })
}

export const useBadgeByIds = (ids: string[], enabled?: boolean) => {
  const { data: { data: badgesData, total } = {}, ...others } = useQuery({
    queryKey: ['getBadgeByIds', ids],
    queryFn: () => fetchBadgeByIds(ids),
    cacheTime: Infinity,
    enabled,
  })
  return { data: badgesData, total, ...others }
}

export const useBadge = (params: FindBadgeDto) => {
  const { data, ...props } = useQuery({
    queryKey: ['find-badge', JSON.stringify(params)],
    queryFn: () => fetchBadge(params),
    enabled:
      (!!params.badgeIds && params.badgeIds.length !== 0) ||
      !!params.limit ||
      !!params.offset ||
      !!params.search,
  })

  return { data: data?.data?.data, total: data?.data?.total, ...props }
}
